define('youtube', ['youtubePlayer', 'youtubeApi'], function(youtubePlayer, youtubeApi) {

  

  return function($videoContainersSelector, props) {

    var initYoutubeC = function() {
      this.props = props;
      this.videos = [];
      this.setContainers();
      this.youtubeApi = youtubeApi;
    };

    initYoutubeC.prototype = {
      loadApi: function() {
        var apiLoaded = function(yTuneApi) {
          this.createPlayers();
        }.bind(this);

        this.youtubeApi().done(apiLoaded);
      },
      shouldLoadApi: function() {
        return this.$videoContainers && this.$videoContainers.length > 0;
      },
      setContainers: function() {
        this.$videoContainers = document.querySelectorAll($videoContainersSelector);
      },
      returnPlayers: function() {
        if (this.callback && typeof this.callback === 'function') {
          this.callback(this.videos);
        }
      },
      isTheatre: function() {
        return this.videos.length > 0 && this.videos[0].isTheatre();
      },
      loadFirst: function() {
        this.videos[0].loadVideoByDemand();
      },
      createPlayers: function() {
        var newPlayer,
          nextPlayer,
          donePlayers,
          count = 0;

        newPlayer = function() {
          new youtubePlayer(this.$videoContainers[count], nextPlayer);
        }.bind(this);

        nextPlayer = function(video) {

          this.videos.push(video);
          count++;

          if (count < this.$videoContainers.length) {
            newPlayer();
          } else {
            donePlayers();
          }
        }.bind(this);

        donePlayers = function(video) {
          this.returnPlayers();
          if (this.isTheatre()) {
            this.loadFirst();
          }
        }.bind(this);

        newPlayer();
      },
      done: function(callback) {
        this.callback = callback;
        if (this.shouldLoadApi()) {
          this.loadApi();
        }
      }
    };

    return new initYoutubeC();
  };


});